import React, { useState, useEffect } from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { navigate, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Faq from "../components/faq";

import Grid from "@mui/material/Grid";

import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";

import screenshot_mail from "../images/screenshot_mail.jpg";
import screenshot_mail_mobile from "../images/screenshot_mail_mobile.jpg";
import screenshot_app from "../images/screenshot_application.jpg";
import { isBrowser } from "../lib/config";
import CountUp from "react-countup";
import { getFromApiUnAuth } from "../lib/api";
import { Alert } from "@mui/material";

const IndexPage = () => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [firstCall, setFirstCall] = useState(true);
  const [follower, setFollower] = useState(0);
  const [likes, setLikes] = useState(0);
  const [subscriber, setSubscriber] = useState(0);

  const getCounts = async () => {
    const counts = await getFromApiUnAuth("/1.0/counts");
    setFollower(counts.follower);
    setLikes(counts.likes);
    setSubscriber(counts.subscriber);
  };

  useEffect(() => {
    setLoaded(true);
    if (isBrowser() && firstCall) {
      setFirstCall(false);
      getCounts();
    }
  }, [firstCall]);

  return (
    <Layout>
      <Seo title={t("Home of my follower")} />
      {loaded ? (
        <div>
          <Slide
            direction="up"
            in={true}
            timeout={1000}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              container
              alignItems="center"
              spacing={3}
              style={{ marginBottom: "40px" }}
            >
              <Grid item xs={12} alignItems="center" spacing={3}>
                <Alert severity="error">
                  X formerly twitter has changed the API. In version 2, access
                  to the follower numbers is limited and no longer freely
                  available. <br />
                  As a result, X is no longer supported.
                </Alert>
              </Grid>
              <Grid item xs={12} sm={7}>
                <h1>
                  <div dangerouslySetInnerHTML={{ __html: t("headline") }} />
                </h1>
                <div dangerouslySetInnerHTML={{ __html: t("text1") }} />
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="flex-end"
                justify="flex-end"
                xs={12}
                sm={5}
              >
                <br />
                <img
                  src={screenshot_mail}
                  alt="Screenshot Mail"
                  width="90%"
                  className="circularImage"
                />
                <img
                  style={{
                    zIndex: 99,
                    marginTop: "110px",
                    marginRight: "-100px",
                    position: "absolute",
                  }}
                  src={screenshot_mail_mobile}
                  alt="Screenshot Mail"
                  height="300"
                  width="245"
                  className="circularImage_2"
                />
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="up"
            in={true}
            timeout={2000}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ marginTop: "40px", marginBottom: "20px" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <h3>
                      <a href="/accounts/">
                        Start <strong className="changeColor">now!</strong> It's{" "}
                        <strong className="changeColor">free</strong>.
                      </a>
                    </h3>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item>
                    <span>What we counted so far</span>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid>follower:&nbsp;&nbsp;</Grid>
                  <Grid style={{ minWidth: 50, textAlign: "left" }}>
                    <strong>
                      <CountUp
                        start={0}
                        delay={1}
                        end={follower}
                        duration={4}
                        useEasing={true}
                        useGrouping={true}
                        separator="."
                        decimals={0}
                        decimal=","
                      />
                    </strong>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid>likes:&nbsp;&nbsp;</Grid>
                    <Grid style={{ minWidth: 50, textAlign: "left" }}>
                      <strong>
                        <CountUp
                          start={0}
                          delay={2}
                          end={likes}
                          duration={4}
                          useEasing={true}
                          useGrouping={true}
                          separator="."
                          decimals={0}
                          decimal=","
                        />
                      </strong>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid>subscriber:&nbsp;&nbsp;</Grid>
                    <Grid style={{ minWidth: 50, textAlign: "left" }}>
                      <strong>
                        <CountUp
                          start={0}
                          delay={3}
                          end={subscriber}
                          duration={4}
                          useEasing={true}
                          useGrouping={true}
                          separator="."
                          decimals={0}
                          decimal=","
                        />
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate("/accounts");
                    }}
                  >
                    sign up
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h2>
                  <div dangerouslySetInnerHTML={{ __html: t("headline2") }} />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: t("text2") }} />
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="up"
            in={true}
            timeout={2000}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <Grid item xs={12} sm={6}>
                <br />
                <img
                  src={screenshot_app}
                  alt="Screenshot Mail"
                  width="90%"
                  className="circularImageLeft"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h2>
                  <div dangerouslySetInnerHTML={{ __html: t("headline3") }} />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: t("text3") }} />
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="up"
            in={true}
            timeout={3000}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ marginTop: "40px" }}
            >
              <Grid item xs={12} sm={8}>
                <h2>
                  <div dangerouslySetInnerHTML={{ __html: t("headline4") }} />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: t("text4") }} />
                <br />
                🔥&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/app/facebook");
                  }}
                  title="get any facebook id"
                >
                  Run it now
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <h3>Have a look into some insights about...</h3>
                <p>
                  <a href="/app/facebook/Shakira" title="Shakira">
                    <strong>Shakira</strong>
                  </a>
                  <br />
                  <a href="/app/facebook/VinDiesel" title="VinDiesel">
                    <strong>Vin Diesel</strong>
                  </a>
                  <br />
                  <a href="/app/facebook/Selena" title="Selena Gomez">
                    <strong>Selena Gomez</strong>
                  </a>
                  <br />
                  <a href="/app/facebook/HuaweiMobileDE" title="Huawei Mobile">
                    <strong>Huawei Mobile</strong>
                  </a>
                  <br />
                  <a href="/app/facebook/TaylorSwift" title="Taylor Swift">
                    <strong>Taylor Swift</strong>
                  </a>
                </p>
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="up"
            in={true}
            timeout={3000}
            mountOnEnter
            unmountOnExit
            style={{ marginTop: "40px" }}
          >
            <Grid container>
              <Faq />
            </Grid>
          </Slide>
          <br />
        </div>
      ) : (
        <div></div>
      )}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
